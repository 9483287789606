import React from 'react'
import GetInTouch from '../components/getInTouch'
import InternalHeader from '../components/internalHeader'
import Img from '../components/responsiveCloudinary'
import { Link } from 'gatsby'
import styled from 'styled-components'

import servicesStyles from '../assets/styles/pages/services.module.scss'
import styles from '../assets/styles/pages/about.module.scss'
import GlobalComponents from '../components/globalcomponents'

const ButtonLinkDefault = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme['neon-pink']};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme['purple-alt']};
    text-decoration: none;
    outline: none;
  }
`

const ButtonLinkHollow = styled(ButtonLinkDefault)`
  border-color: ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    width: initial;
  }

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
`

const AboutPage = () => (
  <GlobalComponents>
    <InternalHeader
      title="Who is Avenue?"
      description="Building great user experiences is what gets us out of bed. We’ve worked all over the world, from San Francisco to Beijing. Sao Paulo is also lovely in the spring."
      flag="About"
      img="wooden_a_ezewk3"
      template="services"
    />
    <div className="row">
      <div className="columns large-8 large-push-2">
        <div className={servicesStyles.services}>
          <h2>Building great user experiences is what gets us out of bed.</h2>
          <span className={styles.subCaption}>
            All creative firms like to state they’re different. We like to think
            our approach to business does actually separate us.
          </span>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="columns large-5 large-push-1">
        <p>
          We’re down-to-earth people committed to going that extra mile and
          designing beautiful experiences for our clients and their customers.
          We’ve worked all over the world, from San Francisco to Beijing. Sao
          Paulo is also lovely in the spring.
        </p>
      </div>
      <div className="columns large-5 large-pull-1">
        <p>
          Our mantra is “Work hard & be nice to people”. To us this means
          providing exceptional customer service through honesty, transparency,
          open communication and delivering end-product that exceeds
          expectations and delivers on our promise.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="columns">
        <h2
          className={`${servicesStyles.diagonalTitle} ${servicesStyles.white}`}
        >
          Who’s leading the pack?
        </h2>
      </div>
    </div>
    <div className="row">
      <Avatar
        name="Flavio Argemi"
        position="Creative Director"
        img="Flavio_V1_vmlnr2"
        imgHover="Flavio_V2_rz0ptv"
      >
        <p>
          Despite there being 21 letters in his full name (true story), people
          just call him&nbsp;‘Fabio’.
        </p>
        <p>
          Flav loves a good video game and playing one of the guitars from
          his&nbsp;collection.
        </p>
      </Avatar>

      <Avatar
        name="Jon Low"
        position="Technical Director"
        img="Jon_V1_sogzdv"
        imgHover="Jon_V2_nsv2qt"
      >
        <p>Without his glasses, he’s a risk to himself and&nbsp;others.</p>
        <p>
          You’ll find Jon cursing on the golf course, or living with the
          injustice of being an Adelaide Crows&nbsp;supporter.
        </p>
      </Avatar>

      <Avatar
        name="Brenton Cannizzaro"
        position="Business &amp; Strategy Director"
        img="Brenton_V2_zghcz2"
        imgHover="Brenton_V1_sdelck"
      >
        <p>
          Believing hair should be long and celebrated, Brenton was ironically
          bald&nbsp;at&nbsp;28.
        </p>
        <p>
          A lover of the world game, he will never walk alone, and also has a
          deep passion for hard rock&nbsp;music.
        </p>
      </Avatar>
    </div>

    <div className={servicesStyles.centerButtons}>
      <ButtonLinkHollow to="/work" type="hollow">
        View our work
      </ButtonLinkHollow>
      <ButtonLinkHollow to="/what-we-do" type="hollow">
        See what we do
      </ButtonLinkHollow>
    </div>

    <div className={styles.whatWereNot}>
      <div className="row">
        <div className="columns large-6">
          <h2 className={styles.notAgency}>
            We are <span>not</span> an agency.
          </h2>
          <p className={styles.goodThing}>(And that’s a good thing!)</p>
          <p className={styles.smallP}>
            Our aim is to become an integral ingredient to our customers’
            businesses, and in doing so, their success. To achieve this we focus
            on constantly delivering value.
          </p>
          <p className={styles.smallP}>
            Our working model challenges the way agencies think, work, and
            charge. With us, you won’t have to worry about hidden fees or mixed
            messages, and here’s why:
          </p>
        </div>
        <div className="columns large-6">
          <h3 className={styles.middleMan}>No middle man.</h3>
          <p className={styles.smallP}>
            We work collaboratively with you. Giving our clients a direct line
            of contact to our directors, designers and developers makes for a
            smoother, smarter experience.
          </p>
          <br />
          <h3>No question marks.</h3>
          <p className={styles.smallP}>
            Working directly with our Directors means you won’t ever be left in
            the dark. Close collaboration, ongoing communication, and a deep
            dive into your business is what helps us get the job done
            efficiently and effectively.
          </p>
        </div>
      </div>
    </div>

    <GetInTouch
      left="In coffee we trust."
      center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
      right="Let's have a chat"
      action="/contact"
    />
  </GlobalComponents>
)

const ImgBlock = styled.div`
  height: 300px;
  position: relative;

  @media (min-width: 1440px) {
    height: 490px;
  }

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 250ms;
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 300px;
    object-fit: cover;

    @media (min-width: 1440px) {
      height: 490px;
      width: 490px;
    }
  }

  img:nth-of-type(2) {
    opacity: 0;
  }

  &:hover img {
    opacity: 1;

    &:nth-of-type(1) {
      opacity: 0;
    }
  }
`

const Avatar = ({ name, position, img, imgHover, children }) => (
  <div className="columns large-4">
    <div className={styles.avatar}>
      <ImgBlock>
        <Img
          user="avenue"
          publicId={img}
          large="490px"
          sourceSizes={[490, 980]}
          alt=""
        />
        <Img
          user="avenue"
          publicId={imgHover}
          large="490px"
          sourceSizes={[490, 980]}
          alt=""
        />
      </ImgBlock>
      <h4>{name}</h4>
      <h5>{position}</h5>
      {children}
    </div>
  </div>
)

export default AboutPage
